import { useEffect } from "react";
import { Stack, Button } from "@mui/material";
import * as utils from "common/utils";
import Constants from "common/constant";

export const Home = () => {
  useEffect(() => {
    if (utils.isIos()) {
      window.location.href = "https://apps.apple.com/jp/app/id1642655214";
    } else if (utils.isAndroid()) {
      if (utils.isFire()) {
        window.location.href = "amzn://apps/android?p=com.inexus.app.parents";
      } else {
        window.location.href =
          "https://play.google.com/store/apps/details?id=com.inexus.app.parents";
      }
    } else {
      window.location.replace(`${Constants.parentsHost}`);
    }
  }, []);

  const handleOpenIos = () => {
    window.location.href = "https://apps.apple.com/jp/app/id1642655214";
  };

  const handleOpenAndroid = () => {
    if (utils.isFire()) {
      window.location.href = "amzn://apps/android?p=com.inexus.app.parents";
    } else {
      window.location.href =
        "https://play.google.com/store/apps/details?id=com.inexus.app.parents";
    }
  };

  return (
    <Stack
      width="100%"
      height="100%"
      direction="column"
      alignItems="center"
      justifyContent="center"
    >
      <Button
        variant="contained"
        onClick={utils.isIos() ? handleOpenIos : handleOpenAndroid}
      >
        ダウンロード
      </Button>
    </Stack>
  );
};
