import platfrom from "platform";

export const isMobile = () => {
  const isIos = platfrom.os.family === "iOS";
  const isFire = FireModels.test(platfrom.os.family);
  const isAndroid = platfrom.os.family === "Android";
  return isIos || isAndroid || isFire;
};

export const isChrome = () => {
  return /Chrome/.test(platfrom.name);
};

export const isIos = () => {
  return platfrom.os.family === "iOS";
};

export const isAndroid = () => {
  return platfrom.os.family === "Android";
};

const FireModels = /KFQUWI|KFTRWI|KFTRPWI|KFONWI|KFMAWI|KFMUWI|KFKAWI|KFSUWI|KFDOWI|KFAUWI|KFGIWI|KFTBWI|KFMEWI|KFFOWI|KFSAWA|KFSAWI|KFASWI|KFARWI|KFTHWA|KFTHWI|KFAPWA|KFAPWI|KFSOWI|KFOT|KFTT|KFJWA|KFJWI|Kindle Fire/;

export const isFire = () => {
  return FireModels.test(platfrom.product);
};
