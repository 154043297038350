export const BASE_WIDTH = 1280;
export const BASE_HEIGHT = 960;

const Constants = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
  parentsHost: process.env.REACT_APP_PARENTS_HOST,
};

export default Constants;
